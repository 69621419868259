<template>
    
    <div v-if="wins" class="flex flex-col ">
        <div class="flex items-center mb-12 mt-6">
            <div class="text-font-gray">Filter by:</div>
            <div class="header-btn-gray ml-3 relative" v-for="(el, index) in typeDocs"
                 :key="index" :class="{ [el.class]: selectedExtensions === el.value }"
                 @click="filterByExtensions(el.value)" >
                <i :class="'mdi mdi-' + el.name"></i>
            </div>
            <div class="ml-auto">
                <div class="relative flex-none w-84">
                    <input type="text" placeholder="Search" v-model="searchInput"
                        class="w-full h-8 rounded bg-body flex flex-row justify-start
                        items-center px-2">
                </div>
            </div>
        </div>
        <div v-if="search(filteredWins).length > 0"  class="h-full oveflow-hidden">
            <div class="scroll-gradient"></div>
            <div class="h-full overflow-auto pb-32">
                <win-card @openFile="openFile" v-for="(el, index) in search(filteredWins)"
                    :key="index" :data="el" />
            </div>

        </div>
        <div v-else class="w-full h-full flex justify-center items-center">
            <span class="module-title text-font-gray">There are no files of the selected extension</span>
        </div>
        <div v-if="file" class="absolute w-full h-full z-50 flex flex-col bg-white rounded-md">
                <div class="h-10 flex flex-row justify-between items-center px-2 bg-body">
                    <span class="text-md text-white font-semibold"></span>
                    <i class="mdi mdi-close text-white font-semibold cursor-pointer"
                        @click="openFile()"></i>
                </div>
                <div class="flex-1 overflow-auto pb-6">
                    <img :src="file" alt="" class="h-122 mx-auto">
                </div>
            </div>
    </div>
    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>

</template>

<script>

const WinCard = () => import('@/components/Globals/PersonalDashboard/Win/WinCard');
import { state } from '@/store';

export default {
    components: {
        WinCard
    },
    data() {
        return {
            wins: null,
            loading: false,
            typeDocs: this.$typeDocs,
            searchInput: '',
            file: false,
            selectedExtensions: []
        }
    },
    methods: {
        filterByExtensions(extensions) {
            if (extensions === this.selectedExtensions) this.selectedExtensions = []
            else this.selectedExtensions = extensions
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Win&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                .then(response => {
                    this.loading = false
                    this.wins = response.data
                })
        },
        search(data) { return this.$search(data, this.searchInput) },
        openFile(file) {
            if (!this.file) { this.file = this.$imageRoute + file.replace(/ /g, "") }
            else { this.file = false }
        }
    },
    computed: {
        filteredWins() {
            if (this.selectedExtensions.length === 0) { return this.wins }
            return this.wins.filter(({ file }) => {
                const pathProps = file.split('.');
                const extension = pathProps[pathProps.length - 1];
                return this.selectedExtensions.includes(extension);
            });
        }
    },
    watch: {
        params() {
            this.load();
        }
    },
    mounted() {
        this.load();

        setInterval(() => {
            this.load();
        }, state.refreshPersonalDashboard);
    }
}
</script>